<template>
  <div>
    <portal to="pageHeaderRight">
      <span
        @click="$router.push({ name: 'AssetList' })"
        class="ab-button--white"
      >{{ $t('app.cancel') }} <i class="icon-close"></i
      ></span>
      <submit-button :isSaving="isSaving" :processing="processing"/>
    </portal>
    <div class="asset-edit">
      <form @submit.prevent="formSubmit" @keydown.enter.prevent.self>
        <div class="grid-x grid-margin-x">
          <input type="submit" id="submitForm" hidden=""/>
          <div class="cell medium-12">
            <h2 class="light">{{ $t('assets.create_asset') }}</h2>
            <div class="spacer--50"></div>
            <div class="grid-x grid-margin-x">
              <div class="cell medium-8">
                <ab-dropzone
                  :fileUpload="true"
                  :data="Object(asset.files)"
                  :id="asset.id"
                  :action-button-text="$t('assets.set_as_preview')"
                  :action-button-active="asset.preview_image.image"
                  @action-button-clicked="useAsPreview"
                  @delete-download="deleteDownload"
                  @image-added="fileAdded"
                  @upload-error="uploadError"
                  @upload-in-progress="uploadInProgress"
                />
                <div class="spacer--20"></div>
                <card-item>
                  <ab-input-field
                    :label="$t('app.title')"
                    v-model="asset.title"
                    type="text"
                    :error="$v.asset.title.$error"
                    :errorMessage="$t('forms.add_valid_title')"
                  ></ab-input-field>

                  <ab-tags-field
                    v-model="selected_campaign"
                    :label="$t('assets.theme_or_campaign')"
                    :maxTags="1"
                    :availableTags="available_campaigns"
                    :alwaysShowAutocomplete="true"
                    :errorMessage="$t('forms.select_campaign')"
                  />

                  <ab-tags-field
                    v-model="selected_channel"
                    :label="$t('assets.channel')"
                    :maxTags="1"
                    :alwaysShowAutocomplete="true"
                    :availableTags="available_channels"
                  />

                  <ab-wysiwyg
                    :label="$t('app.description')"
                    v-model="asset.description"
                  ></ab-wysiwyg>

                  <ab-tags-field
                    v-model="selected_categories"
                    :label="$t('app.category')"
                    :maxTags="1"
                    :availableTags="available_categories"
                    :alwaysShowAutocomplete="true"
                    :errorMessage="$t('forms.select_category')"
                    :error="$v.selected_categories.$error"
                  />

                  <ab-tags-field
                    v-model="selected_tags"
                    :label="$t('app.tags')"
                    :availableTags="available_tags"
                    :alwaysShowAutocomplete="true"
                  />

                  <ab-tags-field
                    v-model="selected_brand_elements"
                    :label="$t('assets.brand_elements')"
                    :availableTags="available_brand_elements"
                    :alwaysShowAutocomplete="true"
                    :add-only-from-autocomplete="true"
                  />

                  <ab-tags-field
                    v-model="selected_languages"
                    :label="$t('app.language')"
                    :maxTags="1"
                    :addOnlyFromAutocomplete="true"
                    :alwaysShowAutocomplete="true"
                    :availableTags="available_languages"
                  />
                </card-item>
              </div>

              <div class="cell medium-4">
                <card-item :title="$t('assets.preview')">
                  <div
                    class="vue-dropzone-single"
                    :class="[$v.uploadedImage.$error ? 'has-error' : '']"
                  >
                    <ab-dropzone
                      v-if="asset"
                      :data="asset.preview_image"
                      :id="asset.id"
                      :relation="'asset'"
                      :dropzoneReady="dropzoneReady"
                      :error="$v.uploadedImage.$error"
                      :errorMessage="$t('forms.select_preview_image')"
                      @image-added="imageAdded"
                      @image-removed="imageRemoved"
                      @upload-error="uploadError"
                      @upload-in-progress="uploadInProgress"
                    />
                  </div>
                </card-item>

                <div class="spacer--20"></div>
                <card-item :title="$t('assets.communicated_on')">
                  <div class="range-picker">
                    <ab-date
                      v-model="date"
                      mode="range"
                      :label="$t('app.date')"
                    />
                  </div>
                </card-item>

                <div class="spacer--20"></div>

                <card-item :title="$t('assets.format')">
                  <ab-input-field
                    v-model="asset.format_width"
                    :label="$t('assets.width')"
                  />
                  <ab-input-field
                    v-model="asset.format_height"
                    :label="$t('assets.height')"
                  />

                  <ab-select
                    :label="$t('assets.unit')"
                    v-model="selected_unit"
                    :options="available_units"
                  ></ab-select>

                </card-item>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import moment from 'moment'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'AssetsCreate',
  data () {
    return {
      asset: {
        preview_image: {},
        files: [],
        category: {}
      },
      selected_categories: [],
      selected_languages: [],
      available_categories: [],
      available_languages: [],
      selected_campaign: [],
      available_campaigns: [],
      tag: '',
      selected_tags: [],
      available_tags: [],
      selected_channel: [],
      available_channels: [],
      dropzoneReady: false,
      uploadedImage: false,
      brand_element: '',
      selected_brand_elements: [],
      available_brand_elements: [],
      // format
      selected_unit: 'mm',
      available_units: [],
      date: {
        start: null,
        end: null
      },
      submitStatus: null,
      isSaving: false,
      processing: false
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/tags`).then((response) => {
      const autoCompleteTags = []
      for (const key in response.data) {
        const tag = response.data[key]
        if (tag.name !== '') {
          const tagData = {
            name: tag.name,
            type: 'tag',
            text: tag.name
          }
          autoCompleteTags.push(tagData)
        }
      }
      this.available_tags = autoCompleteTags.sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    })
    backend
      .get(`${process.env.VUE_APP_URL}/brand-elements`)
      .then((response) => {
        this.available_brand_elements = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_brand_elements.push({
            text: response.data[i].title,
            slug: response.data[i].slug
          })
        }
      })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/categories`)
      .then((response) => {
        this.available_categories = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_categories.push({ text: response.data[i].name })
        }
      })

    backend.get(`${process.env.VUE_APP_URL}/assets/units`).then((response) => {
      this.available_units = []

      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_units.push({
          title: response.data[i].name,
          value: response.data[i].name
        })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/campaigns`).then((response) => {
      this.available_campaigns = []
      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_campaigns.push({ text: response.data[i].name })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/channels`).then((response) => {
      this.available_channels = []
      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_channels.push({ text: response.data[i].name })
      }
    })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/languages`)
      .then((response) => {
        this.available_languages = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_languages.push({
            text: response.data[i].name,
            code: response.data[i].code
          })
        }
      })

    this.$setupKeypress('s', this.formSubmit)
  },
  validations: {
    asset: {
      title: {
        required,
        minLength: minLength(1)
      }
    },
    uploadedImage: {
      required,
      sameAs: sameAs(() => true)
    },
    selected_categories: {
      required
    }
  },
  methods: {
    imageAdded (file) {
      this.processing = false
      this.asset.preview_image = {
        image: file
      }
      this.uploadedImage = true
    },
    fileAdded () {
      this.processing = false
    },
    uploadInProgress () {
      this.processing = true
    },
    uploadError () {
      this.processing = false
    },
    imageRemoved () {
      this.uploadedImage = false
      this.asset.preview_image = {}
    },
    dateFormat () {
      return moment().format('DD/MM/YYYY')
    },
    deleteDownload: function (index) {
      this.asset.files.splice(index, 1)
    },
    formSubmit: function () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        this.asset.category = this.selected_categories[0].text
        if (this.selected_languages.length === 1) {
          this.asset.language = this.selected_languages[0].code
        }
        if (this.selected_campaign.length === 1) {
          this.asset.campaign = this.selected_campaign[0].text
        }

        if (this.selected_channel.length === 1) {
          this.asset.channel = this.selected_channel[0].text
        } else {
          this.asset.channel = null
        }

        if (this.selected_unit) {
          this.asset.unit = this.selected_unit
        }

        this.asset.tags = this.selected_tags
        this.asset.brand_elements = this.selected_brand_elements

        if (this.date == null || this.date.start == null) {
          this.asset.date_from = null
        } else {
          this.asset.date_from = moment(String(this.date.start)).format(
            'YYYY-MM-DD'
          )
        }

        if (this.date == null || this.date.end == null) {
          this.asset.date_to = null
        } else {
          this.asset.date_to = moment(String(this.date.end)).format(
            'YYYY-MM-DD'
          )
        }

        backend
          .post(`${process.env.VUE_APP_URL}/assets`, this.asset)
          .then(() => {
            this.$router.push({ name: 'AssetList' })
          })
          .catch(() => {
            this.isSaving = false
          })
      }
    },
    showErrorDialog: function (message) {
      this.$modal.show('dialog', {
        title: '',
        text: message,
        buttons: [
          {
            title: this.$t('app.close'),
            default: true,
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    useAsPreview (file) {
      this.imageAdded(file)
    }
  }
}
</script>

<style lang="scss">
.vel-toolbar {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.asset-preview {
  cursor: pointer;
}
</style>
